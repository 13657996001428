<template>
  <div class="remind">
    <div class="momey-total" style="font-size: 16px;font-weight: bold;">
      <div style="width:95%;display: flex;justify-content: start;">
        <div class="item">姓名:{{patient.name}}</div>
        <div class="item">性别:{{patient.sex}}</div>
        <div class="item">年龄:{{patient.age}}</div>
        <div class="item item1">诊疗卡号:{{patient.consultation_no}}</div>
      </div>
      <el-button class="color-grey" v-print="print">
        <i class="fa fa-print"></i>
        打印
      </el-button>
      <el-button class="color-grey" @click="setJiDu">
        季度设置
      </el-button>
    </div>
    <div class="gride">
      <div class="back">
        2023
      </div>
      <div class="back">
        <span>第一季度</span> <el-button type="text" size="small" @click="edit(1)" v-if="editShow1"><i
            class="txIons modify"></i></el-button><el-button type="text" size="small" @click="save(season_1,1)"
          v-if="!editShow1"><i class="txIons save"></i></el-button>
      </div>
      <div class="back">
        <span>第二季度</span> <el-button type="text" size="small" @click="edit(2)" v-if="editShow2"><i
            class="txIons modify"></i></el-button><el-button type="text" size="small" @click="save(season_2,2)"
          v-if="!editShow2"><i class="txIons save"></i></el-button>
      </div>
      <div class="back">
        <span>第三季度</span> <el-button type="text" size="small" @click="edit(3)" v-if="editShow3"><i
            class="txIons modify"></i></el-button><el-button type="text" size="small" @click="save(season_3,3)"
          v-if="!editShow3"><i class="txIons save"></i></el-button>
      </div>
      <div class="back backRight">
        <span>第四季度</span> <el-button type="text" size="small" @click="edit(4)" v-if="editShow4"><i
            class="txIons modify"></i></el-button><el-button type="text" size="small" @click="save(season_4,4)"
          v-if="!editShow4"><i class="txIons save"></i></el-button>
      </div>
      
      <div class="back1s">
        <div>透析</div>
        <div>方案</div>
      </div>
      <div class="back1s">
        <div>
          <div><el-input :disabled="editShow1" style="width:90px;margin-right: 5px;" v-model="season_1.num"
              placeholder="请输入"></el-input>次<el-input :disabled="editShow1" v-model="season_1.week"
              style="width:90px;margin-right: 5px;margin-left: 5px;" placeholder="请输入"></el-input>周</div>
          <div><el-input :disabled="editShow1" style="width:100px;margin-right: 5px;" v-model="season_1.hour"
              placeholder="请输入"></el-input>h/次</div>
        </div>
      </div>
      <div class="back1s">
        <div>
          <div><el-input :disabled="editShow2" style="width:90px;margin-right: 5px;" v-model="season_2.num"
              placeholder="请输入"></el-input>次<el-input :disabled="editShow2" v-model="season_2.week"
              style="width:90px;margin-right: 5px;margin-left: 5px;" placeholder="请输入"></el-input>周</div>
          <div><el-input :disabled="editShow2" style="width:100px;margin-right: 5px;" v-model="season_2.hour"
              placeholder="请输入"></el-input>h/次</div>
        </div>
      </div>
      <div class="back1s">
        <div>
          <div><el-input :disabled="editShow3" style="width:90px;margin-right: 5px;" v-model="season_3.num"
              placeholder="请输入"></el-input>次<el-input :disabled="editShow3" v-model="season_3.week"
              style="width:90px;margin-right: 5px;margin-left: 5px;" placeholder="请输入"></el-input>周</div>
          <div><el-input :disabled="editShow3" style="width:100px;margin-right: 5px;" v-model="season_3.hour"
              placeholder="请输入"></el-input>h/次</div>
        </div>
      </div>
      <div class="back1s backRight">
        <div>
          <div><el-input :disabled="editShow4" style="width:90px;margin-right: 5px;" v-model="season_4.num"
              placeholder="请输入"></el-input>次<el-input :disabled="editShow4" v-model="season_4.week"
              style="width:90px;margin-right: 5px;margin-left: 5px;" placeholder="请输入"></el-input>周</div>
          <div><el-input :disabled="editShow4" style="width:100px;margin-right: 5px;" v-model="season_4.hour"
              placeholder="请输入"></el-input>h/次</div>
        </div>
      </div>
      <div class="back2s">
        抗凝方案
      </div>
      <div class="back2s">
        <div>
          <el-checkbox :disabled="editShow1" v-model="season_1.kangning_type11">速碧林 <el-input :disabled="editShow1"
              style="width:90px;margin-right: 5px;" v-model="season_1.kangning_value1"
              placeholder="请输入"></el-input>ml</el-checkbox>
          <el-checkbox :disabled="editShow1" v-model="season_1.kangning_type22">达肝素 <el-input :disabled="editShow1"
              style="width:90px;margin-right: 5px;" v-model="season_1.kangning_value2"
              placeholder="请输入"></el-input>ml</el-checkbox>
        </div>
      </div>
      <div class="back2s">
        <div>
          <el-checkbox :disabled="editShow2" v-model="season_2.kangning_type11">速碧林 <el-input :disabled="editShow2"
              style="width:90px;margin-right: 5px;" v-model="season_2.kangning_value1"
              placeholder="请输入"></el-input>ml</el-checkbox>
          <el-checkbox :disabled="editShow2" v-model="season_2.kangning_type22">达肝素 <el-input :disabled="editShow2"
              style="width:90px;margin-right: 5px;" v-model="season_2.kangning_value2"
              placeholder="请输入"></el-input>ml</el-checkbox>
        </div>
      </div>
      <div class="back2s">
        <div>
          <el-checkbox :disabled="editShow3" v-model="season_3.kangning_type11">速碧林 <el-input :disabled="editShow3"
              style="width:90px;margin-right: 5px;" v-model="season_3.kangning_value1"
              placeholder="请输入"></el-input>ml</el-checkbox>
          <el-checkbox :disabled="editShow3" v-model="season_3.kangning_type22">达肝素 <el-input :disabled="editShow3"
              style="width:90px;margin-right: 5px;" v-model="season_3.kangning_value2"
              placeholder="请输入"></el-input>ml</el-checkbox>
        </div>
      </div>
      <div class="back2s backRight">
        <div>
          <el-checkbox :disabled="editShow4" v-model="season_4.kangning_type11">速碧林 <el-input :disabled="editShow4"
              style="width:90px;margin-right: 5px;" v-model="season_4.kangning_value1"
              placeholder="请输入"></el-input>ml</el-checkbox>
          <el-checkbox :disabled="editShow4" v-model="season_4.kangning_type22">达肝素 <el-input :disabled="editShow4"
              style="width:90px;margin-right: 5px;" v-model="season_4.kangning_value2"
              placeholder="请输入"></el-input>ml</el-checkbox>
        </div>
      </div>
      <div class="back2">
        凝血指标
      </div>
      <div class="back2">
        <div>
          <div>PT：{{season_1.PT}}</div>
          <div>APTT：{{season_1.APTT}}</div>
          <div>血小板：{{season_1.PLT}}</div>
        </div>
      </div>
      <div class="back2">
        <div>
          <div>PT：{{season_2.PT}}</div>
          <div>APTT：{{season_2.APTT}}</div>
          <div>血小板：{{season_2.PLT}}</div>
        </div>
      </div>
      <div class="back2">
        <div>
          <div>PT：{{season_3.PT}}</div>
          <div>APTT：{{season_3.APTT}}</div>
          <div>血小板：{{season_3.PLT}}</div>
        </div>
      </div>
      <div class="back2 backRight">
        <div>
          <div>PT：{{season_4.PT}}</div>
          <div>APTT：{{season_4.APTT}}</div>
          <div>血小板：{{season_4.PLT}}</div>
        </div>
      </div>
      <div class="back2s1">
        贫血指标
      </div>
      <div class="back2s1">
        <div>
          <div>HB：{{season_1.HGB}}</div>
          <div>SF： <el-input :disabled="editShow1" style="width:100px;margin-right: 5px;" v-model="season_1.SF"
              placeholder="请输入"></el-input></div>
          <div>TSAT：<el-input :disabled="editShow1" style="width:100px;margin-right: 5px;" v-model="season_1.TSAT"
              placeholder="请输入"></el-input></div>
        </div>
      </div>
      <div class="back2s1">
        <div>
          <div>HB：{{season_2.HGB}}</div>
          <div>SF： <el-input :disabled="editShow2" style="width:100px;margin-right: 5px;" v-model="season_2.SF"
              placeholder="请输入"></el-input></div>
          <div>TSAT：<el-input :disabled="editShow2" style="width:100px;margin-right: 5px;" v-model="season_2.TSAT"
              placeholder="请输入"></el-input></div>
        </div>
      </div>
      <div class="back2s1">
        <div>
          <div>HB：{{season_3.HGB}}</div>
          <div>SF： <el-input :disabled="editShow3" style="width:100px;margin-right: 5px;" v-model="season_3.SF"
              placeholder="请输入"></el-input></div>
          <div>TSAT：<el-input :disabled="editShow3" style="width:100px;margin-right: 5px;" v-model="season_3.TSAT"
              placeholder="请输入"></el-input></div>
        </div>
      </div>
      <div class="back2s1 backRight">
        <div>
          <div>HB：{{season_4.HGB}}</div>
          <div>SF： <el-input :disabled="editShow4" style="width:100px;margin-right: 5px;" v-model="season_4.SF"
              placeholder="请输入"></el-input></div>
          <div>TSAT：<el-input :disabled="editShow4" style="width:100px;margin-right: 5px;" v-model="season_4.TSAT"
              placeholder="请输入"></el-input></div>
        </div>
      </div>
      <div class="back3s">
        透析充分性
      </div>
      <div class="back3s">
        <div>
          <div>干体重：<el-input :disabled="editShow1" style="width:100px;margin-right: 5px;" v-model="season_1.dry_weight"
              placeholder="请输入"></el-input></div>
          <div>肌酐：{{season_1.CREA}}</div>
          <div>尿素：{{season_1.Urea}}</div>
          <div>PCO2：{{season_1.TCO2}}</div>
          <div>URR：(>65%) {{season_1.URR}}</div>
          <div style="height: 38px;"><el-input :disabled="editShow1" style="width:100px;margin-right: 5px;"
              v-model="season_1.touqian" placeholder="透前尿素"></el-input><el-input :disabled="editShow1"
              style="width:100px;margin-right: 5px;" v-model="season_1.touhou" placeholder="透后尿素"></el-input></div>
          <div>β微球蛋白： <el-input :disabled="editShow1" style="width:100px;margin-right: 5px;" v-model="season_1.weiqiu"
              placeholder="请输入"></el-input></div>
        </div>
      </div>
      <div class="back3s">
        <div>
          <div>干体重：<el-input :disabled="editShow2" style="width:100px;margin-right: 5px;" v-model="season_2.dry_weight"
              placeholder="请输入"></el-input></div>
          <div>肌酐：{{season_2.CREA}}</div>
          <div>尿素：{{season_2.Urea}}</div>
          <div>PCO2：{{season_2.TCO2}}</div>
          <div>URR：(>65%) {{season_2.URR}}</div>
          <div style="height: 38px;"><el-input :disabled="editShow2" style="width:100px;margin-right: 5px;"
              v-model="season_2.touqian" placeholder="透前尿素"></el-input><el-input :disabled="editShow2"
              style="width:100px;margin-right: 5px;" v-model="season_2.touhou" placeholder="透后尿素"></el-input></div>
          <div>β微球蛋白： <el-input :disabled="editShow2" style="width:100px;margin-right: 5px;" v-model="season_2.weiqiu"
              placeholder="请输入"></el-input></div>
        </div>
      </div>
      <div class="back3s">
        <div>
          <div>干体重：<el-input :disabled="editShow3" style="width:100px;margin-right: 5px;" v-model="season_3.dry_weight"
              placeholder="请输入"></el-input></div>
          <div>肌酐：{{season_3.CREA}}</div>
          <div>尿素：{{season_3.Urea}}</div>
          <div>PCO2：{{season_3.TCO2}}</div>
          <div>URR：(>65%) {{season_3.URR}}</div>
          <div style="height: 38px;"><el-input :disabled="editShow3" style="width:100px;margin-right: 5px;"
              v-model="season_3.touqian" placeholder="透前尿素"></el-input><el-input :disabled="editShow3"
              style="width:100px;margin-right: 5px;" v-model="season_3.touhou" placeholder="透后尿素"></el-input></div>
          <div>β微球蛋白： <el-input :disabled="editShow3" style="width:100px;margin-right: 5px;" v-model="season_3.weiqiu"
              placeholder="请输入"></el-input></div>
        </div>
      </div>
      <div class="back3s backRight">
        <div>
          <div>干体重：<el-input :disabled="editShow4" style="width:100px;margin-right: 5px;" v-model="season_4.dry_weight"
              placeholder="请输入"></el-input></div>
          <div>肌酐：{{season_4.CREA}}</div>
          <div>尿素：{{season_4.Urea}}</div>
          <div>PCO2：{{season_4.TCO2}}</div>
          <div>URR：(>65%) {{season_4.URR}}</div>
          <div style="height: 38px;"><el-input :disabled="editShow4" style="width:100px;margin-right: 5px;"
              v-model="season_4.touqian" placeholder="透前尿素"></el-input><el-input :disabled="editShow4"
              style="width:100px;margin-right: 5px;" v-model="season_4.touhou" placeholder="透后尿素"></el-input></div>
          <div>β微球蛋白： <el-input :disabled="editShow4" style="width:100px;margin-right: 5px;" v-model="season_4.weiqiu"
              placeholder="请输入"></el-input></div>
        </div>
      </div>
      <div class="back4">
        营养指标
      </div>
      <div class="back4">
        <div>
          <div>白蛋白：{{season_1.ALB}}</div>
          <div>甘油三酯：{{season_1.TG}}</div>
          <div>总胆固醇：{{season_1.TC}}</div>
          <div>低密度脂蛋白：{{season_1.LDLC}}</div>
        </div>
      </div>
      <div class="back4">
        <div>
          <div>白蛋白：{{season_2.ALB}}</div>
          <div>甘油三酯：{{season_2.TG}}</div>
          <div>总胆固醇：{{season_2.TC}}</div>
          <div>低密度脂蛋白：{{season_2.LDLC}}</div>
        </div>
      </div>
      <div class="back4">
        <div>
          <div>白蛋白：{{season_3.ALB}}</div>
          <div>甘油三酯：{{season_3.TG}}</div>
          <div>总胆固醇：{{season_3.TC}}</div>
          <div>低密度脂蛋白：{{season_3.LDLC}}</div>
        </div>
      </div>
      <div class="back4 backRight">
        <div>
          <div>白蛋白：{{season_4.ALB}}</div>
          <div>甘油三酯：{{season_4.TG}}</div>
          <div>总胆固醇：{{season_4.TC}}</div>
          <div>低密度脂蛋白：{{season_4.LDLC}}</div>
        </div>
      </div>
      <div class="back4s">
        骨、矿物质代谢
      </div>
      <div class="back4s">
        <div>
          <div>血钙：{{season_1.xuegai}}</div>
          <div>血磷：{{season_1.PHOS}}</div>
          <div>钙磷乘积：{{season_1.PHOS * season_1.xuegai}}</div>
          <div>PTH： <el-input :disabled="editShow1" style="width:100px;margin-right: 5px;" v-model="season_1.PTH"
              placeholder="请输入"></el-input></div>
        </div>
      </div>
      <div class="back4s">
        <div>
          <div>血钙：{{season_2.xuegai}}</div>
          <div>血磷：{{season_2.PHOS}}</div>
          <div>钙磷乘积：{{season_2.PHOS * season_2.xuegai}}</div>
          <div>PTH： <el-input :disabled="editShow2" style="width:100px;margin-right: 5px;" v-model="season_2.PTH"
              placeholder="请输入"></el-input></div>
        </div>
      </div>
      <div class="back4s">
        <div>
          <div>血钙：{{season_3.xuegai}}</div>
          <div>血磷：{{season_3.PHOS}}</div>
          <div>钙磷乘积：{{season_3.PHOS * season_3.xuegai}}</div>
          <div>PTH： <el-input :disabled="editShow3" style="width:100px;margin-right: 5px;" v-model="season_3.PTH"
              placeholder="请输入"></el-input></div>
        </div>
      </div>
      <div class="back4s backRight">
        <div>
          <div>血钙：{{season_4.xuegai}}</div>
          <div>血磷：{{season_4.PHOS}}</div>
          <div>钙磷乘积：{{season_4.PHOS * season_4.xuegai}}</div>
          <div>PTH： <el-input :disabled="editShow4" style="width:100px;margin-right: 5px;" v-model="season_4.PTH"
              placeholder="请输入"></el-input></div>
        </div>
      </div>
      <div class="back1">
        <div>电解质</div>
      </div>
      <div class="back1">
        <div>
          <div>血钾：{{season_1.K}}</div>
          <div>血钠：{{season_1.Na}}</div>
        </div>
      </div>
      <div class="back1">
        <div>
          <div>血钾：{{season_2.K}}</div>
          <div>血钠：{{season_2.Na}}</div>
        </div>
      </div>
      <div class="back1">
        <div>
          <div>血钾：{{season_3.K}}</div>
          <div>血钠：{{season_3.Na}}</div>
        </div>
      </div>
      <div class="back1 backRight">
        <div>
          <div>血钾：{{season_4.K}}</div>
          <div>血钠：{{season_4.Na}}</div>
        </div>
      </div>
      <div class="back3">
        其他指标
      </div>
      <div class="back3">
        <div>
          <div>血糖：{{season_1.xuetang}}</div>
          <div>BNP：{{season_1.N}}</div>
          <div>谷丙转氨酶：{{season_1.ALT}}</div>
          <div>白细胞：{{season_1.WBC}}</div>
          <div>糖化血红蛋白：{{season_1.HbA1c}}</div>
          <div>C反应蛋白：{{season_1.CRP}}</div>
        </div>
      </div>
      <div class="back3">
        <div>
          <div>血糖：{{season_2.xuetang}}</div>
          <div>BNP：{{season_2.N}}</div>
          <div>谷丙转氨酶：{{season_2.ALT}}</div>
          <div>白细胞：{{season_2.WBC}}</div>
          <div>糖化血红蛋白：{{season_2.HbA1c}}</div>
          <div>C反应蛋白：{{season_2.CRP}}</div>
        </div>
      </div>
      <div class="back3">
        <div>
          <div>血糖：{{season_3.xuetang}}</div>
          <div>BNP：{{season_3.N}}</div>
          <div>谷丙转氨酶：{{season_3.ALT}}</div>
          <div>白细胞：{{season_3.WBC}}</div>
          <div>糖化血红蛋白：{{season_3.HbA1c}}</div>
          <div>C反应蛋白：{{season_3.CRP}}</div>
        </div>
      </div>
      <div class="back3 backRight">
        <div>
          <div>血糖：{{season_4.xuetang}}</div>
          <div>BNP：{{season_4.N}}</div>
          <div>谷丙转氨酶：{{season_4.ALT}}</div>
          <div>白细胞：{{season_4.WBC}}</div>
          <div>糖化血红蛋白：{{season_4.HbA1c}}</div>
          <div>C反应蛋白：{{season_4.CRP}}</div>
        </div>
      </div>
      <div class="back1s">
        传染病检查
      </div>
      <div class="back1s">
        <div>
          <el-input :disabled="editShow1" type="textarea" style="width:95%;height:95%;margin-right: 5px;"
            v-model="season_1.infectious" placeholder="请输入"></el-input>
        </div>
      </div>
      <div class="back1s">
        <div>
          <el-input :disabled="editShow2" type="textarea" style="width:95%;height:95%;margin-right: 5px;"
            v-model="season_2.infectious" placeholder="请输入"></el-input>
        </div>
      </div>
      <div class="back1s">
        <div>
          <el-input :disabled="editShow3" type="textarea" style="width:95%;height:95%;margin-right: 5px;"
            v-model="season_3.infectious" placeholder="请输入"></el-input>
        </div>
      </div>
      <div class="back1s backRight">
        <div>
          <el-input :disabled="editShow4" type="textarea" style="width:95%;height:95%;margin-right: 5px;"
            v-model="season_4.infectious" placeholder="请输入"></el-input>
        </div>
      </div>
      <div class="back3">
        存在问题、原因分析及处理方案
      </div>
      <div class="back3">
        <div>
          <el-input :disabled="editShow1" type="textarea" style="width:95%;height:100%;margin-right: 5px;"
            v-model="season_1.remark" placeholder="请输入"></el-input>
        </div>
      </div>
      <div class="back3">
        <div>
          <el-input :disabled="editShow2" type="textarea" style="width:95%;height:100%;margin-right: 5px;"
            v-model="season_2.remark" placeholder="请输入"></el-input>
        </div>
      </div>
      <div class="back3">
        <div>
          <el-input :disabled="editShow3" type="textarea" style="width:95%;height:100%;margin-right: 5px;"
            v-model="season_3.remark" placeholder="请输入"></el-input>
        </div>
      </div>
      <div class="back3 backRight">
        <div>
          <el-input :disabled="editShow4" type="textarea" style="width:95%;height:100%;margin-right: 5px;"
            v-model="season_4.remark" placeholder="请输入"></el-input>
        </div>
      </div>
    </div>
    <div id="printAreaJD">
      <h2 style="margin: 5px auto;width: 100%;text-align: center;">惠州市中医医院血透患者季度评估表</h2>
      <div class="momey-total" style="font-size: 16px;font-weight: bold;">
        <div class="item">姓名:{{patient.name}}</div>
        <div class="item">性别:{{patient.sex}}</div>
        <div class="item">年龄:{{patient.age}}</div>
        <div class="item item1">诊疗卡号:{{patient.consultation_no}}</div>
      </div>
      <div class="gride">
        <div class="back">
          2023
        </div>
        <div class="back">
          第一季度
        </div>
        <div class="back">
          第二季度
        </div>
        <div class="back">
          第三季度
        </div>
        <div class="back backRight">
          第四季度
        </div>
        <div class="back1">
          <div>透析</div>
          <div>方案</div>
        </div>
        <div class="back1">
          <div>
            <div>{{season_1.num}}次{{season_1.week}}周</div>
            <div>{{season_1.hour}}h/次</div>
          </div>
        </div>
        <div class="back1">
          <div>
            <div>{{season_2.num}}次{{season_2.week}}周</div>
            <div>{{season_2.hour}}h/次</div>
          </div>
        </div>
        <div class="back1">
          <div>
            <div>{{season_3.num}}次{{season_3.week}}周</div>
            <div>{{season_3.hour}}h/次</div>
          </div>
        </div>
        <div class="back1 backRight">
          <div>
            <div>{{season_4.num}}次{{season_4.week}}周</div>
            <div>{{season_4.hour}}h/次</div>
          </div>
        </div>
        <div class="back2">
          抗凝方案
        </div>
        <div class="back2">
          <div>
            <div><span v-if="season_1.kangning_type1==1">☑</span><span v-else>☐</span>速碧林 {{season_1.kangning_value1}}
              ml</div>
            <div><span v-if="season_1.kangning_type2==1">☑</span><span v-else>☐</span>达肝素 {{season_1.kangning_value2}}
              ml</div>
          </div>
        </div>
        <div class="back2">
          <div>
            <div><span v-if="season_2.kangning_type1==1">☑</span><span v-else>☐</span>速碧林 {{season_2.kangning_value1}}
              ml</div>
            <div><span v-if="season_2.kangning_type2==1">☑</span><span v-else>☐</span>达肝素 {{season_2.kangning_value2}}
              ml</div>
          </div>
        </div>
        <div class="back2">
          <div>
            <div><span v-if="season_3.kangning_type1==1">☑</span><span v-else>☐</span>速碧林 {{season_3.kangning_value1}}
              ml</div>
            <div><span v-if="season_3.kangning_type2==1">☑</span><span v-else>☐</span>达肝素 {{season_3.kangning_value2}}
              ml</div>
          </div>
        </div>
        <div class="back2 backRight">
          <div>
            <div><span v-if="season_4.kangning_type1==1">☑</span><span v-else>☐</span>速碧林 {{season_4.kangning_value1}}
              ml</div>
            <div><span v-if="season_4.kangning_type2==1">☑</span><span v-else>☐</span>达肝素 {{season_4.kangning_value2}}
              ml</div>
          </div>
        </div>
        <div class="back2">
          凝血指标
        </div>
        <div class="back2">
          <div>
            <div>PT：{{season_1.PT}}</div>
            <div>APTT：{{season_1.APTT}}</div>
            <div>血小板：{{season_1.PLT}}</div>
          </div>
        </div>
        <div class="back2">
          <div>
            <div>PT：{{season_2.PT}}</div>
            <div>APTT：{{season_2.APTT}}</div>
            <div>血小板：{{season_2.PLT}}</div>
          </div>
        </div>
        <div class="back2">
          <div>
            <div>PT：{{season_3.PT}}</div>
            <div>APTT：{{season_3.APTT}}</div>
            <div>血小板：{{season_3.PLT}}</div>
          </div>
        </div>
        <div class="back2 backRight">
          <div>
            <div>PT：{{season_4.PT}}</div>
            <div>APTT：{{season_4.APTT}}</div>
            <div>血小板：{{season_4.PLT}}</div>
          </div>
        </div>
        <div class="back2">
          贫血指标
        </div>
        <div class="back2">
          <div>
            <div>HB：{{season_1.HGB}}</div>
            <div>SF：{{season_1.SF}}</div>
            <div>TSAT：{{season_1.TSAT}}</div>
          </div>
        </div>
        <div class="back2">
          <div>
            <div>HB：{{season_2.HGB}}</div>
            <div>SF：{{season_2.SF}}</div>
            <div>TSAT：{{season_2.TSAT}}</div>
          </div>
        </div>
        <div class="back2">
          <div>
            <div>HB：{{season_3.HGB}}</div>
            <div>SF：{{season_3.SF}}</div>
            <div>TSAT：{{season_3.TSAT}}</div>
          </div>
        </div>
        <div class="back2 backRight">
          <div>
            <div>HB：{{season_4.HGB}}</div>
            <div>SF：{{season_4.SF}}</div>
            <div>TSAT：{{season_4.TSAT}}</div>
          </div>
        </div>
        <div class="back3">
          透析充分性
        </div>
        <div class="back3">
          <div>
            <div>干体重：{{season_1.dry_weight}}</div>
            <div>肌酐：{{season_1.CREA}}</div>
            <div>尿素：{{season_1.Urea}}</div>
            <div>PCO2：{{season_1.TCO2}}</div>
            <div>URR：(>65%) {{season_1.URR}}</div>
            <div>β微球蛋白：{{season_1.weiqiu}}</div>
          </div>
        </div>
        <div class="back3">
          <div>
            <div>干体重：{{season_2.dry_weight}}</div>
            <div>肌酐：{{season_2.CREA}}</div>
            <div>尿素：{{season_2.Urea}}</div>
            <div>PCO2：{{season_2.TCO2}}</div>
            <div>URR：(>65%) {{season_2.URR}}</div>
            <div>β微球蛋白：{{season_2.weiqiu}}</div>
          </div>
        </div>
        <div class="back3">
          <div>
            <div>干体重：{{season_3.dry_weight}}</div>
            <div>肌酐：{{season_3.CREA}}</div>
            <div>尿素：{{season_3.Urea}}</div>
            <div>PCO2：{{season_3.TCO2}}</div>
            <div>URR：(>65%) {{season_3.URR}}</div>
            <div>β微球蛋白：{{season_3.weiqiu}}</div>
          </div>
        </div>
        <div class="back3 backRight">
          <div>
            <div>干体重：{{season_4.dry_weight}}</div>
            <div>肌酐：{{season_4.CREA}}</div>
            <div>尿素：{{season_4.Urea}}</div>
            <div>PCO2：{{season_4.TCO2}}</div>
            <div>URR：(>65%) {{season_4.URR}}</div>
            <div>β微球蛋白：{{season_4.weiqiu}}</div>
          </div>
        </div>
        <div class="back4">
          营养指标
        </div>
        <div class="back4">
          <div>
            <div>白蛋白：{{season_1.ALB}}</div>
            <div>甘油三酯：{{season_1.TG}}</div>
            <div>总胆固醇：{{season_1.TC}}</div>
            <div>低密度脂蛋白：{{season_1.LDLC}}</div>
          </div>
        </div>
        <div class="back4">
          <div>
            <div>白蛋白：{{season_2.ALB}}</div>
            <div>甘油三酯：{{season_2.TG}}</div>
            <div>总胆固醇：{{season_2.TC}}</div>
            <div>低密度脂蛋白：{{season_2.LDLC}}</div>
          </div>
        </div>
        <div class="back4">
          <div>
            <div>白蛋白：{{season_3.ALB}}</div>
            <div>甘油三酯：{{season_3.TG}}</div>
            <div>总胆固醇：{{season_3.TC}}</div>
            <div>低密度脂蛋白：{{season_3.LDLC}}</div>
          </div>
        </div>
        <div class="back4 backRight">
          <div>
            <div>白蛋白：{{season_4.ALB}}</div>
            <div>甘油三酯：{{season_4.TG}}</div>
            <div>总胆固醇：{{season_4.TC}}</div>
            <div>低密度脂蛋白：{{season_4.LDLC}}</div>
          </div>
        </div>
        <div class="back4">
          骨、矿物质代谢
        </div>
        <div class="back4">
          <div>
            <div>血钙：{{season_1.xuegai}}</div>
            <div>血磷：{{season_1.PHOS}}</div>
            <div>钙磷乘积：{{season_1.PHOS * season_1.xuegai}}</div>
            <div>PTH：{{season_1.PTH}}</div>
          </div>
        </div>
        <div class="back4">
          <div>
            <div>血钙：{{season_2.xuegai}}</div>
            <div>血磷：{{season_2.PHOS}}</div>
            <div>钙磷乘积：{{season_2.PHOS * season_2.xuegai}}</div>
            <div>PTH：{{season_2.PTH}}</div>
          </div>
        </div>
        <div class="back4">
          <div>
            <div>血钙：{{season_3.xuegai}}</div>
            <div>血磷：{{season_3.PHOS}}</div>
            <div>钙磷乘积：{{season_3.PHOS * season_3.xuegai}}</div>
            <div>PTH：{{season_3.PTH}}</div>
          </div>
        </div>
        <div class="back4 backRight">
          <div>
            <div>血钙：{{season_4.xuegai}}</div>
            <div>血磷：{{season_4.PHOS}}</div>
            <div>钙磷乘积：{{season_4.PHOS * season_4.xuegai}}</div>
            <div>PTH：{{season_4.PTH}}</div>
          </div>
        </div>
        <div class="back1">
          <div>电解质</div>
        </div>
        <div class="back1">
          <div>
            <div>血钾：{{season_1.K}}</div>
            <div>血钠：{{season_1.Na}}</div>
          </div>
        </div>
        <div class="back1">
          <div>
            <div>血钾：{{season_2.K}}</div>
            <div>血钠：{{season_2.Na}}</div>
          </div>
        </div>
        <div class="back1">
          <div>
            <div>血钾：{{season_3.K}}</div>
            <div>血钠：{{season_3.Na}}</div>
          </div>
        </div>
        <div class="back1 backRight">
          <div>
            <div>血钾：{{season_4.K}}</div>
            <div>血钠：{{season_4.Na}}</div>
          </div>
        </div>
        <div class="back3">
          其他指标
        </div>
        <div class="back3">
          <div>
            <div>血糖：{{season_1.xuetang}}</div>
            <div>BNP：{{season_1.N}}</div>
            <div>谷丙转氨酶：{{season_1.ALT}}</div>
            <div>白细胞：{{season_1.WBC}}</div>
            <div>糖化血红蛋白：{{season_1.HbA1c}}</div>
            <div>C反应蛋白：{{season_1.CRP}}</div>
          </div>
        </div>
        <div class="back3">
          <div>
            <div>血糖：{{season_2.xuetang}}</div>
            <div>BNP：{{season_2.N}}</div>
            <div>谷丙转氨酶：{{season_2.ALT}}</div>
            <div>白细胞：{{season_2.WBC}}</div>
            <div>糖化血红蛋白：{{season_2.HbA1c}}</div>
            <div>C反应蛋白：{{season_2.CRP}}</div>
          </div>
        </div>
        <div class="back3">
          <div>
            <div>血糖：{{season_3.xuetang}}</div>
            <div>BNP：{{season_3.N}}</div>
            <div>谷丙转氨酶：{{season_3.ALT}}</div>
            <div>白细胞：{{season_3.WBC}}</div>
            <div>糖化血红蛋白：{{season_3.HbA1c}}</div>
            <div>C反应蛋白：{{season_3.CRP}}</div>
          </div>
        </div>
        <div class="back3 backRight">
          <div>
            <div>血糖：{{season_4.xuetang}}</div>
            <div>BNP：{{season_4.N}}</div>
            <div>谷丙转氨酶：{{season_4.ALT}}</div>
            <div>白细胞：{{season_4.WBC}}</div>
            <div>糖化血红蛋白：{{season_4.HbA1c}}</div>
            <div>C反应蛋白：{{season_4.CRP}}</div>
          </div>
        </div>
        <div class="back1">
          传染病检查
        </div>
        <div class="back1">
          <div>

          </div>
        </div>
        <div class="back1">
          <div>

          </div>
        </div>
        <div class="back1">
          <div>

          </div>
        </div>
        <div class="back1 backRight">
          <div>

          </div>
        </div>
        <div class="back4">
          存在问题、原因分析及处理方案
        </div>
        <div class="back4">
          <div>

          </div>
        </div>
        <div class="back4">
          <div>

          </div>
        </div>
        <div class="back4">
          <div>

          </div>
        </div>
        <div class="back4 backRight">
          <div>

          </div>
        </div>
      </div>
    </div>
    <BaseDialog
    title="季度设置"
    :isshow="modRemind"
    @handleShow="
      (val) => {
        modRemind = val
      }
    "
    width="500px"
    height="auto"
  >
    <div class="schemeBox">
      <div class="userItem">
        <div class="back1s">
          <div>第一季度</div>
        </div>
        <div class="back1s">
          <div style="display: flex;justify-content: space-between; margin: 10px;">
            <div><el-date-picker v-model="timeSet.season1_start_time" value-format="YYYY-MM-DD" type="date" placeholder="开始日期"
             ></el-date-picker></div>
              <div><el-date-picker v-model="timeSet.season1_end_time" value-format="YYYY-MM-DD" type="date" placeholder="结束日期"
               ></el-date-picker></div>
          </div>
        </div>
        <div class="back1s">
          <div>第二季度</div>
        </div>
        <div class="back1s">
          <div style="display: flex;justify-content: space-between; margin: 10px;">
            <div><el-date-picker v-model="timeSet.season2_start_time" value-format="YYYY-MM-DD" type="date" placeholder="开始日期"
              ></el-date-picker></div>
              <div><el-date-picker v-model="timeSet.season2_end_time" value-format="YYYY-MM-DD" type="date" placeholder="结束日期"
               ></el-date-picker></div>
          </div>
        </div>
        <div class="back1s">
          <div>第三季度</div>
        </div>
        <div class="back1s">
          <div style="display: flex;justify-content: space-between; margin: 10px;">
            <div><el-date-picker v-model="timeSet.season3_start_time" value-format="YYYY-MM-DD" type="date" placeholder="开始日期"
              ></el-date-picker></div>
              <div><el-date-picker v-model="timeSet.season3_end_time" value-format="YYYY-MM-DD" type="date" placeholder="结束日期"
                ></el-date-picker></div>
          </div>
        </div>
        <div class="back1s">
          <div>第四季度</div>
        </div>
        <div class="back1s backRight">
          <div style="display: flex;justify-content: space-between; margin: 10px;">
            <div><el-date-picker v-model="timeSet.season4_start_time" value-format="YYYY-MM-DD" type="date" placeholder="开始日期"
              ></el-date-picker></div>
              <div><el-date-picker v-model="timeSet.season4_end_time" value-format="YYYY-MM-DD" type="date" placeholder="结束日期"
               ></el-date-picker></div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          class="color-cancel"
          type="primary"
          @click="modRemind = false"
        >
          取消
        </el-button>
        <el-button
          @click="saveRemind"
          :loading="saveLoading"
          class="color-determine"
          type="primary"
        >
          确定
        </el-button>
      </div>
    </template>
  </BaseDialog>
  </div>
</template>
<script>
  import { ref, reactive, toRefs, nextTick } from 'vue'
  import { useRoute } from 'vue-router'
  import service from '@/utils/request'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import BaseDialog from '@/components/Dialog/index.vue'
  export default {
    name: 'test',
    components: {
      BaseDialog,
    },
    setup() {
      const route = useRoute()
      const state = reactive({
        editShow1: true,
        editShow2: true,
        editShow3: true,
        editShow4: true,
        print: {
          id: 'printAreaJD',
          popTitle: '季度评估',
        },
        searchData: {
          patient_id: route.params.id,
        },
        timeSet:{},
        modRemind:false,
        ruleForm: {},
        patient: '',
        season_1: {
          kangning_type11: false,
          kangning_type22: false,
          kangning_type1: '',
          kangning_type2: '',
          "num": " ",
          "week": " ",
          "hour": " ",
          "PT": " ",
          "APTT": " ",
          "PLT": " ",
          "HGB": " ",
          "SF": " ",
          "TSAT": " ",
          "dry_weight": " ",
          "CREA": " ",
          "Urea": " ",
          "TCO2": " ",
          "URR": " ",
          "weiqiu": " ",
          "ALB": " ",
          "TG": " ",
          "TC": " ",
          "LDLC": " ",
          "xuegai": " ",
          "PHOS": " ",
          "PTH": " ",
          "K": " ",
          "Na": " ",
          "xuetang": " ",
          "N": " ",
          "ALT": " ",
          "WBC": " ",
          "HbA1c": " ",
          "CRP": " "
        },
        season_2: {
          kangning_type11: false,
          kangning_type22: false,
          kangning_type1: '',
          kangning_type2: '',
          "num": " ",
          "week": " ",
          "hour": " ",
          "PT": " ",
          "APTT": " ",
          "PLT": " ",
          "HGB": " ",
          "SF": " ",
          "TSAT": " ",
          "dry_weight": " ",
          "CREA": " ",
          "Urea": " ",
          "TCO2": " ",
          "URR": " ",
          "weiqiu": " ",
          "ALB": " ",
          "TG": " ",
          "TC": " ",
          "LDLC": " ",
          "xuegai": " ",
          "PHOS": " ",
          "PTH": " ",
          "K": " ",
          "Na": " ",
          "xuetang": " ",
          "N": " ",
          "ALT": " ",
          "WBC": " ",
          "HbA1c": " ",
          "CRP": " "
        },
        season_3: {
          kangning_type11: false,
          kangning_type22: false,
          kangning_type1: '',
          kangning_type2: '',
          "num": " ",
          "week": " ",
          "hour": " ",
          "PT": " ",
          "APTT": " ",
          "PLT": " ",
          "HGB": " ",
          "SF": " ",
          "TSAT": " ",
          "dry_weight": " ",
          "CREA": " ",
          "Urea": " ",
          "TCO2": " ",
          "URR": " ",
          "weiqiu": " ",
          "ALB": " ",
          "TG": " ",
          "TC": " ",
          "LDLC": " ",
          "xuegai": " ",
          "PHOS": " ",
          "PTH": " ",
          "K": " ",
          "Na": " ",
          "xuetang": " ",
          "N": " ",
          "ALT": " ",
          "WBC": " ",
          "HbA1c": " ",
          "CRP": " "
        },
        season_4: {
          kangning_type11: false,
          kangning_type22: false,
          kangning_type1: '',
          kangning_type2: '',
          "num": " ",
          "week": " ",
          "hour": " ",
          "PT": " ",
          "APTT": " ",
          "PLT": " ",
          "HGB": " ",
          "SF": " ",
          "TSAT": " ",
          "dry_weight": " ",
          "CREA": " ",
          "Urea": " ",
          "TCO2": " ",
          "URR": " ",
          "weiqiu": " ",
          "ALB": " ",
          "TG": " ",
          "TC": " ",
          "LDLC": " ",
          "xuegai": " ",
          "PHOS": " ",
          "PTH": " ",
          "K": " ",
          "Na": " ",
          "xuetang": " ",
          "N": " ",
          "ALT": " ",
          "WBC": " ",
          "HbA1c": " ",
          "CRP": " "
        },
      })
      const initData = () => {
        getDataList()
      }
      const getDataList = async () => {
        let res = await service.post(
          '/api/patient/get_his_season_report',
          state.searchData
        )
        if (res.code === 0) {
          state.patient = res.data.patient
          if (JSON.stringify(res.data.season_1) == "{}") {
            console.log(111)
            for (let item in state.season_1) {
              state.season_1[item] = ''
              state.season_1.kangning_type11 = false
              state.season_1.kangning_type22 = false
            }
          } else {
            state.season_1 = res.data.season_1
            if (state.season_1.kangning_type1 == 1) {
              state.season_1.kangning_type11 = true
            } else {
              state.season_1.kangning_type11 = false
            }
            if (state.season_1.kangning_type2 == 1) {
              state.season_1.kangning_type22 = true
            } else {
              state.season_1.kangning_type22 = false
            }
          }
          if (JSON.stringify(res.data.season_2) == "{}") {
            for (let item in state.season_2) {
              state.season_2[item] = ''
              state.season_2.kangning_type11 = false
              state.season_2.kangning_type22 = false
            }
          } else {
            state.season_2 = res.data.season_2
            if (state.season_2.kangning_type1 == 1) {
              state.season_2.kangning_type11 = true
            } else {
              state.season_2.kangning_type11 = false
            }
            if (state.season_2.kangning_type2 == 1) {
              state.season_2.kangning_type22 = true
            } else {
              state.season_2.kangning_type22 = false
            }
          }
          if (JSON.stringify(res.data.season_3) == "{}") {
            console.log(111)
            for (let item in state.season_3) {
              state.season_3[item] = ''
              state.season_3.kangning_type11 = false
              state.season_3.kangning_type22 = false
            }
          } else {
            state.season_3 = res.data.season_3
            if (state.season_3.kangning_type1 == 1) {
              state.season_3.kangning_type11 = true
            } else {
              state.season_3.kangning_type11 = false
            }
            if (state.season_3.kangning_type2 == 1) {
              state.season_3.kangning_type22 = true
            } else {
              state.season_3.kangning_type22 = false
            }
          }
          if (JSON.stringify(res.data.season_4) == "{}") {
            console.log(111)
            for (let item in state.season_4) {
              state.season_4[item] = ''
              state.season_4.kangning_type11 = false
              state.season_4.kangning_type22 = false
            }
          } else {
            state.season_4 = res.data.season_4
            if (state.season_4.kangning_type1 == 1) {
              state.season_4.kangning_type11 = true
            } else {
              state.season_4.kangning_type11 = false
            }
            if (state.season_4.kangning_type2 == 1) {
              state.season_4.kangning_type22 = true
            } else {
              state.season_4.kangning_type22 = false
            }
          }
        }
      }
      const edit = (code) => {
        if (code == 1) {
          state.editShow1 = false
        }
        if (code == 2) {
          state.editShow2 = false
        }
        if (code == 3) {
          state.editShow3 = false
        }
        if (code == 4) {
          state.editShow4 = false
        }
      }
      const save = async (row, code) => {
        if (code == 1) {
          state.editShow1 = true
        }
        if (code == 2) {
          state.editShow2 = true
        }
        if (code == 3) {
          state.editShow3 = true
        }
        if (code == 4) {
          state.editShow4 = true
        }
        let type1 = ''
        let type2 = ''
        console.log('row.kangning_type11==', row.kangning_type11)
        console.log('row.kangning_type1==', row.kangning_type1)
        console.log('row.kangning_type22==', row.kangning_type22)
        if (row.kangning_type11 == false) {
          type1 = 0
        } else {
          type1 = 1
        }
        if (row.kangning_type22 == false) {
          type2 = 0
        } else {
          type2 = 1
        }
        console.log(type1, type2)
        let urr = ''
        if (row.URR != '' || row.URR != null || row.URR != undefined) {
          console.log(row.touqian,row.touhou)
          if (row.touqian == '' && row.touhou == ''&& row.touqian == undefined && row.touhou == undefined && row.touqian !=null && row.touhou != null) {
            urr = ''
            console.log(1)
          }else if(row.touqian != '' && row.touhou != '' && row.touqian != undefined && row.touhou != undefined ){
            let b = (Number(row.touqian) - Number(row.touhou)) / Number(row.touqian) * 100
            urr = b.toFixed(2)
            console.log('非空')
          }else{
            urr = row.URR
          }
        }
        console.log(urr)
        let data = {
          patient_id: state.searchData.patient_id,
          season: code,
          num: row.num,
          week: row.week,
          hour: row.hour,
          kangning_type1: type1,
          kangning_value1: row.kangning_value1,
          kangning_type2: type2,
          kangning_value2: row.kangning_value2,
          dry_weight: row.dry_weight,
          SF: row.SF,
          URR: urr,
          weiqiu: row.weiqiu,
          PTH: row.PTH,
          infectious: row.infectious,
          remark: row.remark,
          TSAT: row.TSAT
        }
        let res = await service.post(
          '/api/patient/update_his_season_report',
          data
        )
        if (res.code === 0) {
          ElMessage.success(res.msg)
          getDataList()
        }

      }
      const setJiDu = () =>{
        state.modRemind = true
        getInfo()
      }
      const getInfo = async() => {
          let url = '/api/patient/get_his_season_set'
          let data = {
            patient_id:route.params.id
          }
          let res = await service.post(url, data)
          if (res.code === 0) {
            console.log(res.data)
            state.timeSet = res.data
          }
    }
      const saveRemind = async() => {
          let url = '/api/patient/update_his_season_set'
          let data = {
            ...state.timeSet,
            patient_id:route.params.id
          }
          let res = await service.post(url, data)
          if (res.code === 0) {
            ElMessage.success(res.msg)
            getDataList()
            state.modRemind = false
          }
    }

      return {
        ...toRefs(state),
        initData,
        getDataList,
        save,
        edit,
        saveRemind,
        getInfo,
        setJiDu
      }
    },
  }
</script>

<style scoped lang="scss">
  .search-top {
    margin-bottom: 12px;
  }

  .schemeBox {
    margin-top: 16px;
  }

  .momey-total {
    display: flex;
    margin: 10px;
    line-height: 30px;

    .item {
      margin-right: 20px;
      width: 150px;
    }
  }
  .momey-total1 {
    display: flex;
    margin: 3px;
    /* line-height: 30px; */
    .item {
      margin-right: 20px;
      width: 150px;
    }
  }
  .gride {
    display: grid;
    grid-template-columns: 12% 22% 22% 22% 22%;
    grid-template-rows: repeat(1, 40px);
    align-items: center;

    ::v-deep .el-checkbox .el-checkbox__label {
      font-size: 16px;
      font-family: "Source Han Sans CN-Regular";
      color: #000;
    }

    .back {
      background-color: #f5f5f5;
      font-size: 18px;
      text-align: left;
      /* padding: 12px; */
      height: 40px;
      line-height: 40px;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      padding-left: 8px;
      padding-right: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .backRight {
      border-right: 1px solid #ccc;
    }

    .back1,
    .back2,
    .back3,
    .back4 {
      font-size: 15px;
      padding: 5px 0;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      padding-left: 10px;

      div {
        width: 100%;
      }
    }

    .back1 {
      height: 50px;
      display: flex;
      align-items: center;
    }

    .back1s {
      height: 90px;
      line-height: 38px;
      display: flex;
      align-items: center;
      font-size: 15px;
      padding: 5px 0;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      padding-left: 10px;

      div {
        width: 100%;
      }
    }

    .back2 {
      height: 70px;
      display: flex;
      align-items: center;
    }

    .back2s1 {
      height: 105px;
      display: flex;
      align-items: center;
      font-size: 15px;
      padding: 5px 0;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      padding-left: 10px;

      div {
        width: 100%;
      }
    }

    .back2s {
      height: 85px;
      display: flex;
      align-items: center;
      font-size: 15px;
      padding: 5px 0;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      padding-left: 10px;

      div {
        width: 100%;
      }
    }

    .back3 {
      height: 130px;
      display: flex;
      align-items: center;
    }

    .back3s {
      height: 190px;
      display: flex;
      align-items: center;
      font-size: 15px;
      padding: 5px 0;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      padding-left: 10px;

      div {
        width: 100%;
      }
    }

    .back4 {
      height: 90px;
      display: flex;
      align-items: center;
    }

    .back4s {
      height: 100px;
      display: flex;
      align-items: center;
      font-size: 15px;
      padding: 5px 0;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      padding-left: 10px;

      div {
        width: 100%;
      }
    }
  }

  #printAreaJD {
    position: absolute;
    z-index: -1;

    .printTab {}

  }
</style>